import { Component } from '@angular/core';

@Component({
  selector: 'pet-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'PeTec';
  version = 'Dato: 27-03-2022, V' + '1.5 - Angular 15.x';
}

<div class="st_bred">
  <h1>Kontakt PeTec:</h1>
  <p>
    E-mail <a href="mailto:kontakt@petec.dk"><span class="st_bred">kontakt&#64;petec.dk</span></a><br />
    CVR.NR: 40549234 <br /><br />
  </p>
  <p>
    <strong>Åbningstider:
    </strong><br />
    Mandag til torsdag 10.00-15.00<br />
    Fredag 9.00-14.00
  </p>
 <br/>
  <p> 
    Driftstatus: <span class="PeTec_gron">Normal</span>
    <br />
    Ved tekniske problemer undenfor normal åbningstid kan vi altid kontaktes via e-mail på <a target="_new"
      href="mailto:support@petec.dk"><u><span class="st_bred">support&#64;Petec.dk</span></u></a>
  </p>
</div>
<!--

<table class="st_bred" cellPadding="3" cellSpacing="0" border="0" style="display:normal">
  <tbody>
    <tr>
      <td align="left"><strong>En erfaren partner:</strong> PeTec er et team bestående
        af
        veluddannede medarbejdere, med mange års erfaring.
        <p>
          Vi leverer totale løsninger til den professionelle virksomhed, der koncentrerer
          deres
          indsats
          om virksomhedens kerneområde, og ligeledes ønsker en professionel
          sparringpartner. Vi
          blev etableret i 1992 og forretningsområdet tager primært udgangspunkt i
          internetapplikationer.
        </p>
      </td>
      <td><img src="assets/images/lyt.gif" alt="Erfaren partner" /></td>
    </tr>
    <tr>
      <td><strong>Forretningsområder:</strong> Vi tilbyder <a href="serverhosting">ServerHosting</a> samt
        <a href="webhotel">Web-Hoteller</a> til vores erhvervskunder. I naturlig
        forlængelse
        heraf kan vi tilbyde kreativt <a href="webdesign">WebDesign</a>, der visualisere
        kundernes mange
        ønsker. Vi er specialiseret og yder <a href="support">support</a>
        indenfor
        Microsoft produkter. <span style="display:none">samt <a href="firewall">FireWall løsninger</a> og <a href="vpn">Virtuelt Privat Network</a>
        (VPN)</span></td>
      <td><img src="assets/images/solsikke.bmp" alt="Solsikke" /></td>
    </tr>
    <tr>
      <td align="left">
          <strong>PeTec CMS:</strong> er et brugervenligt, fleksibelt og produktivt Content
          Management værktøj, som er udviklet af software selskabet Telerik Inc.,
          som er en førende leverandør af udviklingsværktøjer og komponenter til
          .NET.

      </td>
      <td align="left" vAlign="top">
        <p>
          <img src="assets/images/decentral.gif" alt="Decentral-IT-afdeling" />
        </p>
      </td>
    </tr>
  </tbody>
</table> -->
<div>

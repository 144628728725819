import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pet-klippekort',
  templateUrl: './klippekort.component.html',
  styleUrls: ['./klippekort.component.css']
})
export class KlippekortComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<pet-layout>
  <div id="main">
    <content>
      <router-outlet></router-outlet>
    </content>
    <nav>
      <img src="assets/images/logo_ring.png" width="284" height="200" border="0" alt="Forsiden" usemap="#image-logo">
      <map name="image-logo">
        <area target="_self" alt="Forside" title="Forside" href="produkter" coords="33,12,144,122" shape="rect">
      </map>
      <aside>
        <ul>
          <!--  <li><a href="cmssystem">CMS-System</a></li> -->
          <li><a href="webhotel">&nbsp;</a></li>
          <li><a href="webdesign">&nbsp;</a></li>
          <li><a href="serverhosting">&nbsp;</a></li>
          <li><a href="driftsstatus"><span class="vMenuUNormalDrift">&nbsp;</span></a></li>
        </ul>
        <img style="margin: -85px 0 0 172px;" src="assets/images/lodret_streg.png" width="10" height="198" border="0"
          alt="" />
      </aside>
      <span style="display: none;">{{ version }} </span>
    </nav>
  </div>
</pet-layout>

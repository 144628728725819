<header>
  <!-- Image Map Generated by http://www.image-map.net/ -->
   <img src="assets/images/top-trans.png" usemap="#image-map">
 <!-- 
  <map name="image-map">
    <area target="" alt="Produkt" title="Produkt" href="produkter" coords="13,64,80,85" shape="rect">
    <area target="" alt="Support" title="Support" href="support" coords="94,65,148,85" shape="rect">
    <area target="" alt="Reference" title="Reference" href="referencer" coords="160,68,234,85" shape="rect">
    <area target="" alt="Login" title="Login" href="login" coords="244,68,282,85" shape="rect">
    <area target="" alt="Kontakt" title="Kontakt" href="kontakt" coords="296,67,346,85" shape="rect">
    <area target="" alt="Web-mail" title="Web-mail" href="https://www.onlinemail.io/" coords="357,67,418,85" shape="rect">
  </map>
  -->
</header>

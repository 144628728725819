<div class="st_bred">
<h1 align="center"><a target="_blank" href="">Klippekort</a> </h1>
<p align="center">
  Login for kunder med klippekort support
</p>
<h1 align="center"><br /></h1>
<h1 align="center"><a target="_blank" href="">Statistik</a> </h1>
<p align="center">
  Login for kunder med statistik modul
</p>

</div>

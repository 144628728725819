import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* import af PeTec Moduler */
import { UiModule } from './ui/ui.module';
import { ProdukterComponent } from './indhold/produkter/produkter.component';
import { SupportComponent } from './indhold/support/support.component';
import { ReferencerComponent } from './indhold/referencer/referencer.component';
import { LoginComponent } from './indhold/login/login.component';
import { KontaktComponent } from './indhold/kontakt/kontakt.component';
import { WebMailComponent } from './indhold/web-mail/web-mail.component';
/* Venstre navi */
import { ServerhostingComponent } from './leftnav/cms-system/serverhosting/serverhosting.component';
import { WebdesignComponent } from './leftnav/cms-system/webdesign/webdesign.component';
import { WebhotelComponent } from './leftnav/cms-system/webhotel/webhotel.component';
import { CmsSystemComponent } from './leftnav/cms-system/cms-system/cms-system.component';
import { FirewallComponent } from './indhold/support/firewall/firewall.component';
import { VpnComponent } from './indhold/support/vpn/vpn.component';
import { DriftsstatusComponent } from './leftnav/cms-system/driftsstatus/driftsstatus.component';
import { KlippekortComponent } from './indhold/support/klippekort/klippekort.component';

@NgModule({
  declarations: [
    AppComponent,
    ProdukterComponent,
    SupportComponent,
    ReferencerComponent,
    LoginComponent,
    KontaktComponent,
    WebMailComponent,
   /*  Venstre navigation */
    CmsSystemComponent,
    WebhotelComponent,
    WebdesignComponent,
    ServerhostingComponent,
    DriftsstatusComponent,
    FirewallComponent,
    VpnComponent,
    KlippekortComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UiModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

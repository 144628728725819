<div class="st_bred">
  <h1>Kontakt PeTec:</h1>
  <p>
    E-mail <a href="mailto:kontakt@petec.dk"><span class="st_bred">kontakt&#64;petec.dk</span></a><br />
  </p><br />
  <p>
    <strong>Åbningstider:
    </strong><br />
    Mandag til torsdag 10.00-15.00<br />
    Fredag 9.00-14.00
  </p>
  <p>
    <br />
    Ved tekniske problemer undenfor normal åbningstid kan vi altid kontaktes via e-mail på <a target="_new"
      href="mailto:support@petec.dk"><u><span class="st_bred">support&#64;petec.dk</span></u></a>.
  </p>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pet-webdesign',
  templateUrl: './webdesign.component.html',
  styleUrls: ['./webdesign.component.css']
})
export class WebdesignComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="st_bred">
  <h1>Klippekort ordning</h1>
  <p>PeTec tilbyder et smidigt klippekortsystem, der bakkes op af et stærkt team med stor erfaring.</p>
  <p>Med et klippekort sikrer du dig en hurtig besvarelse på opgaver og problemstillinger samtidig med at der ydes op
    til 25% rabat på normalprisen.</p>
  <p>Med et klippekort kan du bruge dine klip til alle former for support og/eller konsulentbesøg (excl. kørsel).</p>
  <h1>Priser</h1>
  <table cellPadding="3" cellSpacing="0" border="0">
    <tbody>
      <tr>
        <td>
          <p>Klippekort 10 </p>
        </td>
        <td>
          <p align="right">4.975,- DKK</p>
        </td>
      </tr>
      <tr>
        <td>Klippekort 20</td>
        <td>
          <p align="right">8.995,- DKK</p>
        </td>
      </tr>
      <tr>
        <td>Klippekort 50</td>
        <td>
          <p align="right">19.900,- DKK</p>
        </td>
      </tr>
      <tr>
        <td>Klippekort firewall 20</td>
        <td>
          <p align="right">11.940,- DKK</p>
        </td>
      </tr>
      <tr>
        <td>Klippekort firewall 50&nbsp;</td>
        <td>
          <p align="right">25.373,- DKK</p>
        </td>
      </tr>
    </tbody>
  </table>
  <h1>Support uden klippekort?</h1>
  <p>Det er naturligvis også muligt at få support uden et klippekort - prisen beregnes da udfra vores normale timepris
    som p.t. udgør DKK/time 995,-. Vi debiterer minimum for 30 min. (DKK 497,50) pr. opgave.</p>
</div>

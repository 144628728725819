import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProdukterComponent } from './indhold/produkter/produkter.component';
import { SupportComponent } from './indhold/support/support.component';
import { ReferencerComponent } from './indhold/referencer/referencer.component';
import { LoginComponent } from './indhold/login/login.component';
import { KontaktComponent } from './indhold/kontakt/kontakt.component';
import { KlippekortComponent } from './indhold/support/klippekort/klippekort.component';
import { VpnComponent } from './indhold/support/vpn/vpn.component';
import { FirewallComponent } from './indhold/support/firewall/firewall.component';
import { WebMailComponent } from './indhold/web-mail/web-mail.component';
import { WebdesignComponent } from './leftnav/cms-system/webdesign/webdesign.component';
import { ServerhostingComponent } from './leftnav/cms-system/serverhosting/serverhosting.component';
import { DriftsstatusComponent } from './leftnav/cms-system/driftsstatus/driftsstatus.component';
import { WebhotelComponent } from './leftnav/cms-system/webhotel/webhotel.component';
import { CmsSystemComponent } from './leftnav/cms-system/cms-system/cms-system.component';

const routes: Routes = [
  {
    path: 'produkter',
    component: ProdukterComponent,
  },
  {
    path: 'support',
    component: SupportComponent,
  },
  {
    path: 'referencer',
    component: ReferencerComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'kontakt',
    component: KontaktComponent,
  },
  {
    path: 'webMail',
    component: WebMailComponent,
  },
  {
    path: 'cmssystem',
    component: CmsSystemComponent,
  },
  {
    path: 'webhotel',
    component: WebhotelComponent,
  },
  {
    path: 'webdesign',
    component: WebdesignComponent,
  },
  {
    path: 'serverhosting',
    component: ServerhostingComponent,
  },
  {
    path: 'driftsstatus',
    component: DriftsstatusComponent,
  },
  /*  Support */
  {
    path: 'klippekort',
    component: KlippekortComponent,
  },
  {
    path: 'firewall',
    component: FirewallComponent,
  },
  {
    path: 'vpn',
    component: VpnComponent,
  },
  { path: '', redirectTo: '/produkter', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

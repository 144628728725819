<div class="st_bred">
<h1>PeTec har valg Sitefinity CMS </h1> <em>- som fremtidens CMS-værktøj</em>


    <h2> Hvad er Sitefinity CMS?</h2>

  <p style="display:none">
    Sitefinity CMS er et brugervenligt, fleksibelt og produktivt Content Management-værktøj, som er
    udviklet af softwareselskabet <a href="/composite-77.htm">Telerik Inc</a>., som er en førende
    leverandør af udviklingsværktøjer og komponenter til .NET.
  </p>

    <h2>Hvem henvender Sitefinity CMS sig til?</h2>
    <p>
    Sitefinity CMS henvender sig til såvel små som store virksomheder, der ønsker at arbejde kreativt
</p>
  <p>
    Når web-løsningen først er implementeret og designet er fastlagt, er det nemt at oprette tekst,
    billeder, flash og video på en effektiv og overskuelig måde. Skabeloner sikrer at alle sider overholder
    de fastlagte layout-rammer. Medarbejderne skal blot vælge en skabelon og oprette sidens indhold - med
    <strong>Sitefinity CMS</strong> kan alle medarbejder, der kender til tekstbehandling være med i
    vedligeholdelse af hjemmesiden - uden kendskab til teknik og programmering.
  </p>
<p>
    Med <strong>Sitefinity CMS</strong> er det muligt at uddelegerer arbejdet til flere medarbejdere, grupper og
    afdelinger. Du kan styre hvem der må oprette, redigere, offentliggøre eller slette en side. Det kan
    bl.a. bruges til, at siden bliver kvalitetssikret inden den offentlige udgivelse.</p>
<h2>Sitefinity indeholder følgende af moduler:</h2>

  <ul>
    <li>Blogs </li>
    <li>Events </li>
    <li>Forum </li>
    <li>Intranet/Extranet </li>
    <li>List (til fx FAQ, kontakter, To-Do, Produtlister etc.) </li>
    <li>News </li>
    <li>Newsletter </li>
    <li>RSS feeds </li>
    <li>Search </li>
  </ul>
  <p>
    <strong>For dem der vil vide lidt mere!</strong><br />
    Hjemmesidens funktionalitet og layout defineres ved hjælp af skabeloner og temaer, som er baseret på
    standard ASP.NET Masterpages og Themes. De funktionelle elementer i Sitefinity-sider er opbygget ved hjælp af
    kontroller, ligesom i enhver standard ASP.NET-løsning. Hvis du har brug for nogle specielle funktioner, kan
    du selv oprette dem som .NET brugerkontroller med Visual Studio og hurtigt tilføje dem til Sitefinity
    værktøjskassen.
  </p>
  <br />
  <p>
    <strong>Integration med andre IT-systemer</strong><br />
    Sitefinity CMS kan administrere flere website-, intranet- og ekstranet løsninger i samme system og med
    genbrug af indhold på tværs af løsningerne.
  </p>
  <p>
    &nbsp;
  </p>
</div>


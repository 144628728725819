<h1>Vores referencer tæller bl.a.:</h1>
<table class="st_bred" cellPadding="3" cellSpacing="0" border="0">
  <tbody>
    <tr>
      <td align="left">- CMS system &amp; design<br />
        - Internet server drift/hosting<br />
        - DNS drift/vedligeholdelse<br />
      </td>
      <td align="left"><img src="assets/media/1030_22.jpg"
          alt="MJEriksson" /></td>
    </tr>
    <tr>
      <td>-Internet server (IIS) drift/hosting<br />
        - SQL drift/hosting<br />
      </td>
      <td><img src="assets/media/1030_20.gif" alt="GN" /></td>
    </tr>
    <tr>
      <td>- Microsoft Exchange Server<br />
        - Visual Basic programmering<br />
        <br />
      </td>
      <td><img src="assets/media/1030_28.gif" alt="TDC" /></td>
    </tr>
    <tr>
      <td><a target="_new" href="http://www.odenseenergi.dk"></a>- CMS system &amp; design<br />
        - Internet server drift/hosting<br />
        - DNS drift/vedligeholdelse<br />
        - Microsoft Exchange Server<br />
        - Visual Basic programmering</td>
      <td><img src="assets/media/1030_25.gif"
          alt="Odense Energi" /></td>
    </tr>
    <tr>
      <td>- CMS system &amp; design<br />
        - Internet server drift/hosting<br />
        - DNS drift/vedligeholdelse<br />
      </td>
      <td><img src="assets/media/1030_21.gif" alt="Ikast" /></td>
    </tr>
    <tr>
      <td>- Internet server drift/hosting<br />
        - DNS drift/vedligeholdelse<br />
        - Udvikling div. kommunikationsmoduler<br />
        - Visual Basic programmering<br />
      </td>
      <td><img src="assets/media/1030_24.gif" alt="NFS" /></td>
    </tr>
    <tr>
      <td>- CMS system &amp; design<br />
        - Microsoft NT Server<br />
        - Microsoft Exchange Server<br />
        - Internet server drift/hosting<br />
        - DNS drift/vedligeholdelse<br />
        - CMS system<br />
        - Netværk (WAN)<br />
        - Firewall<br />
        - Udvikling div. beregningsmoduler<br />
        - .NET programering<br />
      </td>
      <td><img src="assets/media/1030_27.gif" alt="Scan-Energi" />
      </td>
    </tr>
    <tr>
      <td colSpan="2"><a target="_new" href="http://www.okf.dk"></a>
        <p align="left">
          - CMS system &amp; design&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img
            src="assets/media/1030_26.jpg" alt="OKF" /><br />
          - DNS drift/vedligeholdelse<br />
          - Internet server (IIS) drift/hosting1
        </p>
      </td>
    </tr>
    <tr>
      <td>- Microsoft NT Server<a target="_new" href="http://www.ge.dk"></a><br />
        - Microsoft Exchanger Server<br />
        - Internet server drift/hosting<br />
        - DNS drift/vedligeholdelse<br />
        - Netværk (WAN)<br />
        - Firewall<br />
        - Internetside - programmering/design<br />
        - Udvikling - div. beregningsmoduler</td>
      <td><img src="assets/media/1030_19.gif" alt="Galten"
          border="0" /></td>
    </tr>
    <tr>
      <td>
        <p align="left">
          - Microsoft NT Server<br />
          - Microsoft Exchanger Server<br />
          - Internet server drift/hosting<br />
          - DNS drift/vedligeholdelse<br />
          - Firewall<br />
          - Udvikling af div. beregningsmuduler
        </p>
      </td>
      <td><img src="assets/media/1030_18.gif" alt="EnCon" /></td>
    </tr>
    <tr>
      <td>- Landsdækkende levering/service af online printere</td>
      <td><img src="assets/media/1030_31.gif" alt="Tips" /></td>
    </tr>
  </tbody>
</table>

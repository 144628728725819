<h1>WebDesign</h1>
<table cellPadding="3" cellSpacing="0" border="0" class="st_bred">
  <tbody>
    <tr>
      <td align="left" vAlign="top">
        <p>
          <br />
          PeTec har specialiseret sig i at skabe virksomhedsprofiler på Internettet. Vores webteam
          består af professionelle IT-folk, som på let og forståelig måde kan oms&aelig;tte din
          virksomhedsprofil til et elektronisk budskab på Internettet.
        </p>
        <p>
          <a href=""></a>
        </p>
        <p>
          <br />
          Webteamet kan bistå din virksomhed med vejledning og undervisning i, hvordan man bedst muligt udnytter
          de mange muligheder, som informationsteknologien stiller til rådighed.
        </p>
        <p>
          Tr&aelig;nger din virksomheds internetside til en &quot;ansigtsl&oslash;ftning&quot; giver vi gerne et
          prisoverslag - det eneste du bedes g&oslash;re er at sende os dine &oslash;nsker og krav og du vil modtage et
          uforpligtende prisoverslag.
        </p>
      </td>
      <td align="left" vAlign="top">
        <p>
          <a href="http://www.vivostat.com/"><img src="assets/media/1030_44.jpg" alt="Vivostat" /></a><br />
          <br />
          <a href="http://www.energifyn.dk"><img src="assets/media/1030_45.jpg" alt="Odense Energi A/S" /></a>
        </p>
      </td>
    </tr>
  </tbody>
</table>
<p>
  &nbsp;
</p>

<div class="st_bred">
  <h1 style="color: #8b0000">Driftsforstyrrelser</h1>

  <p class="normalDrift"><b>Normal drift</b></p>

  <div class="unormalDrift">
    <h4><span>06-05-2020 16.10</span> </h4>
    <p style="margin-top:-10px;">Der er i øjeblikket driftsproblemer i vores server miljø</p>
    <div>

    </div>
  </div>
</div>

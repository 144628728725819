import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pet-web-mail',
  templateUrl: './web-mail.component.html',
  styleUrls: ['./web-mail.component.css']
})
export class WebMailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="st_bred">
  <h1>Support</h1>
  <p>
    Tidens installationer bliver stadige mere og mere komplekse og kan til tider virke uoverskuelige for selv garvede
    systemfolk. Der indgår langt flere faktorer og kilder til fejl end for blot et par år tilbage.
  </p>
  <h2>Vi er specialiseret og supportere nedenstående:</h2>
  <ul>
    <li>Microsoft NT server</li>
    <li>Microsoft Exchange server/klienter</li>
    <li>Microsoft Internet Information Server (IIS)</li>
    <li>Microsoft SQL server</li>
    <li>Microsoft Outlook og Schedule+</li>
    <li>Routeropsætning</li>
    <li>Internet generelt</li>
    <li>Fejlfinding vedrørende Internet forbindelsen (ISP/ISDN/ADSL)</li>
    <li>Firewall - overvågning af netværk samt div. sikkerhedstests</li>
    <li>Statistik af internet trafik</li>
    <li>Composite Content Management System (CMS)</li>
  </ul>

  <table cellPadding="0" cellSpacing="0">
    <tbody>
      <tr>
        <td>_______________________________________________________ </td>
      </tr>
    </tbody>
  </table>
  <div class="center">
    <span style="color: #00a885"></span><a href="klippekort"><span style="color: #00a885">Klippekort ordning</span></a>
    <span style="color:black">&ensp;|&ensp;</span><a target="_new" href="../../../assets/media/1030_17.pdf"><span style="color: #00a885">Tekst/PDF</span></a>
  </div>
</div>

<table class="st_bred" cellPadding="3" cellSpacing="0" border="0">
  <tbody>
    <tr>
      <td align="left" colSpan="2" vAlign="top">
        <h1>ServerHosting</h1>
        <p>
          <strong>Hvorfor hoste hos PeTec?<br />
          </strong>Vi har nu mulighed for at tilbyde hosting af servere, i hvad vi og vores kunder mener er Danmarks
          bedste fiberoptiske netv&aelig;rk.<br />
          PeTec har indgået samarbejde med jay.net og kan derfor tilbyde alle former for hosting til en
          yderst favorable pris.
        </p>
      </td>
    </tr>
    <tr>
      <td align="left" vAlign="top">Vi ved hvad vi taler om - og hvad der kr&aelig;ves af en professionel hosting
        partner. Netv&aelig;rket hvori din server bliver placeret er opbygget på den mest optimale og redundante
        facon, for at im&oslash;dekomme de h&oslash;jeste krav omkring sikkerhed, oppetid og hastighed.
        <p>
          <em><strong>Alt sammen for at sikre at netop din server altid er online</strong></em>.
        </p>
      </td>
      <td align="right" vAlign="top"><img src="assets/media/1030_5.jpg" alt="ServerHosting" />
      </td>
    </tr>
  </tbody>
</table>

<div class="st_bred">
<h1>WebHotel</h1>
<p>Ved at oprette et domainnavn (www.domainnavn.dk), sikrer du dig retten til at anvende
  navnet på Internettet. Domænenavnet er en forudsætning for at have en hjemmeside med en dertil
  hørende e-mail konto (<a target="_new" href="mailto:dit-navn@domainnavn.dk">dit-navn&#64;domainnavn.dk</a>).</p>
<p>
  Nedenstående viser de forskellige former for WebHoteller. Disse kan til enhver tid konverteres til et
  størrer eller mindre hvis behovet skulle opstå.</p>
<p>
  Såfremt du ønsker en SQL database-løsning eller du ikke finder informationerne tilstr&aelig;kkelig,
  bedes du <a href="mailto:support@petec.dk"> kontakte os for yderligere!</a></p>
</div>
